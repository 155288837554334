import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-single-layout",
    templateUrl: "./single-layout.component.html",
    styleUrls: ["./single-layout.component.scss"]
})
export class SingleLayoutComponent {
    constructor() { }
}
