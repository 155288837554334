import { Injectable } from "@angular/core";
import { IOAuthLoginResult } from "./models/IOAuthLoginResult";
import { Maybe, Nothing } from "purify-ts";
import { environment } from "../environments/environment";

export interface IOrganizationSettings {
    key: string;
    id3qsdn: string;
    webinarStart: string;
    waitingRoomFeature: boolean;
    panoSwf: string;
    panoXml: string;
    waitingRoomAssets: string;
    lecturehallBg: string;
    losTrackingEndpoint: string;
    clickTracking?: {
        endpoint?: string;
        token?: string;
        tenantId?: string;
    };
}

@Injectable()
export class OAuthStorageAdapter {
    static readonly CURRENT_OAUTH_RESULT = "current_oauth_result";
    static readonly CURRENT_OAUTH_ORGANIZATION = "current_oauth_organization";

    public static clearStorage(): void {
        sessionStorage.clear();
    }

    getCurrentOAuthResult(): Maybe<IOAuthLoginResult> {
        const storedAuthorization = sessionStorage.getItem(OAuthStorageAdapter.CURRENT_OAUTH_RESULT);

        if (storedAuthorization === null) {
            return Nothing;
        }

        try {
            return Maybe.of(JSON.parse(storedAuthorization));
        } catch (error) {
            return Nothing;
        }
    }

    setCurrentOauthResult(oauthResult: IOAuthLoginResult) {
        sessionStorage.setItem(OAuthStorageAdapter.CURRENT_OAUTH_RESULT, JSON.stringify(oauthResult));
    }

    setCurrentOrganizationKey(key: string) {
        sessionStorage.setItem(OAuthStorageAdapter.CURRENT_OAUTH_ORGANIZATION, key);
    }

    getCurrentOrganizationKey() {
        return sessionStorage.getItem(OAuthStorageAdapter.CURRENT_OAUTH_ORGANIZATION);
    }

    getOrganizationSettings(): IOrganizationSettings | null {
        const auth = this.getCurrentOAuthResult().orDefault(null);
        return environment.organizations.find((org) => org.key === auth.selectedOrganization.key);
    }
}
