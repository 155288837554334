import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ReceptionComponent } from "./components/reception/reception.component";
import { AuthenticationGuard } from "./AuthenticationGuard";
import { IEGuard } from "./IEGuard";
import { FullLayoutComponent } from "./components/full-layout/full-layout.component";
import { SingleLayoutComponent } from "./components/single-layout/single-layout.component";
import { AboutComponent } from "./components/about/about.component";
import { DataLegalComponent } from "./components/data-legal/data-legal.component";
import { LectureHallComponent } from "./components/lecturehall/lecturehall.component";
import { WaitingRoomComponent } from "./components/waitingroom/waitingroom.component";
import { FastLoginComponent } from "./components/fastlogin/fastlogin.component";
import { LoginComponent } from "./components/login/login.component";
import { RegistrationComponent } from "./components/registration/registration.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { NoIEComponent } from "./components/noie/noie.component";

const routes: Routes = [
    {
        path: "",
        component: SingleLayoutComponent,
        canActivate: [AuthenticationGuard, IEGuard],
        children: [
            {
                path: "",
                redirectTo: "reception",
                pathMatch: "full"
            },
            {
                path: "reception",
                component: ReceptionComponent,
                canActivate: [AuthenticationGuard]
            },
            {
                path: "waitingroom",
                component: WaitingRoomComponent,
                canActivate: [AuthenticationGuard]
            },
            {
                path: "lecturehall",
                component: LectureHallComponent,
                canActivate: [AuthenticationGuard]
            }
        ]
    },
    {
        path: "",
        component: FullLayoutComponent,
        canActivate: [IEGuard],
        children: [
            { path: "enter/:token", component: FastLoginComponent, pathMatch: "full" },
            { path: "login", component: LoginComponent, pathMatch: "full" },
            { path: "registration", component: RegistrationComponent, pathMatch: "full" },
            { path: "reset_password", component: ResetPasswordComponent, pathMatch: "full" },
            { path: "perform_password_reset", component: ResetPasswordComponent, pathMatch: "full" }
        ]
    },
    {
        path: "",
        component: FullLayoutComponent,
        children: [
            { path: "data_legal", component: DataLegalComponent, pathMatch: "full" },
            { path: "imprint", component: AboutComponent, pathMatch: "full" },
            { path: "noie", component: NoIEComponent, pathMatch: "full" },
            {
                path: "404",
                component: AboutComponent,
                pathMatch: "full"
            },
            {
                path: "**",
                redirectTo: "/404"
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
