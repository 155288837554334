import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { BaseComponent } from "./BaseComponent";

@Component({ selector: "", template: `<div></div>` })
export abstract class OrganizationBaseComponent extends BaseComponent implements OnInit, OnDestroy {
    public organizationKey: string = "vd2021q3";

    constructor(protected router: Router, protected route: ActivatedRoute) {
        super();
    }

    ngOnInit(): void {}

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
