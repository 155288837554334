import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { IOAuthLoginResult } from "../models/IOAuthLoginResult";
import { Observable } from "rxjs";
import { OAuthStorageAdapter } from "../OAuthStorageAdapter";
@Injectable({
    providedIn: "root"
})
export class AuthenticationService {
    constructor(private http: HttpClient, private oauthStorageAdapter: OAuthStorageAdapter) {}

    login(username: string, password: string, organizationKey: string): Observable<IOAuthLoginResult> {
        const httpParams = new HttpParams({
            fromObject: {
                grant_type: "password",
                username: username,
                password: password
            }
        });

        const httpHeaders = new HttpHeaders({
            "Content-Type": "application/x-www-form-urlencoded"
        });

        return this.http.post<IOAuthLoginResult>(`${environment.oauthBaseAPI}/login?o=${organizationKey}`, httpParams, { headers: httpHeaders });
    }

    fastlogin(token: string, organizationKey: string): Observable<IOAuthLoginResult> {
        const httpParams = new HttpParams({
            fromObject: {
                grant_type: "password",
                username: token,
                password: token
            }
        });

        const httpHeaders = new HttpHeaders({
            "Content-Type": "application/x-www-form-urlencoded"
        });

        return this.http.post<IOAuthLoginResult>(`${environment.oauthBaseAPI}/fastlogin?o=${organizationKey}`, httpParams, { headers: httpHeaders });
    }

    profile(): Observable<Object> {
        const oauth = this.oauthStorageAdapter.getCurrentOAuthResult().orDefault(null);

        const httpHeaders = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: `Bearer ${oauth?.accessToken}`
        });

        return this.http.get<Object>(`${environment.baseAPI}profile`, {
            headers: httpHeaders
        });
    }

    logout(): Observable<void> {
        const oauth = this.oauthStorageAdapter.getCurrentOAuthResult().orDefault(null);

        if (!oauth) {
            return;
        }
        const httpHeaders = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: `Bearer ${oauth.accessToken}`
        });

        return this.http.post<any>(`${environment.oauthBaseAPI}revoke`, null, {
            headers: httpHeaders
        });
    }
}
