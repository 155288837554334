import { BrowserModule } from "@angular/platform-browser";
import { NgModule, Injector, APP_INITIALIZER } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ReceptionComponent } from "./components/reception/reception.component";
import { LectureHallComponent } from "./components/lecturehall/lecturehall.component";
import { WaitingRoomComponent } from "./components/waitingroom/waitingroom.component";
import { FastLoginComponent } from "./components/fastlogin/fastlogin.component";
import { LoginComponent } from "./components/login/login.component";
import { RegistrationComponent } from "./components/registration/registration.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthenticationGuard } from "./AuthenticationGuard";
import { IEGuard } from "./IEGuard";
import { OAuthStorageAdapter } from "./OAuthStorageAdapter";
import { TranslateModule, TranslateService, TranslateLoader } from "@ngx-translate/core";
import { translateLoaderService } from "./global/translate/translateloader.service";
import { LOCATION_INITIALIZED } from "@angular/common";
import { AlertComponent } from "./components/alert/alert.component";
import { HttpErrorInterceptor } from "./http-error.interceptor";
import { SingleLayoutComponent } from "./components/single-layout/single-layout.component";
import { FullLayoutComponent } from "./components/full-layout/full-layout.component";
import { AboutComponent } from "./components/about/about.component";
import { DataLegalComponent } from "./components/data-legal/data-legal.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { RouteReuseStrategy } from "@angular/router";
import { CustomRouteReuseStrategy } from "./global/CustomRouteReuseStrategy";
import { AlertDialogComponent } from "./components/alert-dialog/alert-dialog.component";
import { NoIEComponent } from "./components/noie/noie.component";
import { MatDialogModule } from "@angular/material/dialog";
import { LosTrackingService } from "./services/lostracking.service";

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
    return () =>
        new Promise<any>((resolve: any) => {
            const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
            locationInitialized.then(() => {
                translate.addLangs(["en"]);

                const langToSet = "en";
                translate.setDefaultLang("en");
                translate.use(langToSet).subscribe(
                    () => {
                        // language initialized
                    },
                    (err) => {
                        console.error(`Problem with '${langToSet}' language initialization.'`);
                    },
                    () => {
                        resolve(null);
                    }
                );
            });
        });
}

export const httpInterceptorProviders = [{ provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }];
@NgModule({
    declarations: [
        AppComponent,
        ReceptionComponent,
        LoginComponent,
        RegistrationComponent,
        ResetPasswordComponent,
        FastLoginComponent,
        AlertComponent,
        AboutComponent,
        DataLegalComponent,
        SingleLayoutComponent,
        FullLayoutComponent,
        LectureHallComponent,
        WaitingRoomComponent,
        AlertDialogComponent,
        NoIEComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        TranslateModule.forRoot(),
        BrowserAnimationsModule,
        MatIconModule,
        MatListModule,
        MatDividerModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule
    ],
    providers: [
        AuthenticationGuard,
        LosTrackingService,
        IEGuard,
        OAuthStorageAdapter,
        httpInterceptorProviders,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true
        },
        {
            provide: TranslateLoader,
            useFactory: translateLoaderService,
            deps: [HttpClient]
        },
        {
            provide: RouteReuseStrategy,
            useClass: CustomRouteReuseStrategy
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
