<div id="contentContainer">
    <div class="body-content">
        <h1 itemprop="headline">Datenschutzerklärung</h1>

        <!-- AddThis requires an API key to render -->

        <p><strong>Aktualisiert: 06.09.2018 </strong></p>
        <p>Ihr Vertrauen ist uns wichtig.</p>
        <p>
            Wir wollen sicherstellen, dass Sie wissen, welche personenbezogenen Daten wir bei Interaktionen mit GlaxoSmithKline GmbH &amp; Co. KG und der GSK-Firmengruppe („GSK“, „wir“, „uns“ und „unser“) über Sie erheben, wie wir Ihre
            personenbezogenen Daten verwenden und wie wir diese schützen.
        </p>
        <p>Diese Datenschutzerklärung erläutert folgende Fragen:</p>
        <ul>
            <li><a href="#HL1">Welche personenbezogenen Daten erheben wir?</a></li>
            <li><a href="#HL2">Wie verwenden wir Ihre personenbezogenen Daten?</a></li>
            <li><a href="#HL3">Auf welcher Grundlage verwenden wir Ihre personenbezogenen Daten?</a></li>
            <li><a href="#HL4">Wie lange speichern wir Ihre personenbezogenen Daten?</a></li>
            <li><a href="#HL5">Wir geben Ihre personenbezogenen Daten gelegentlich an Dritte weiter und übertragen Sie ins Ausland</a></li>
            <li><a href="#HL6">Schutz Ihrer personenbezogenen Daten</a></li>
            <li><a href="#HL7">Websites, die uns nicht gehören und die sich unserer Kontrolle entziehen</a></li>
            <li><a href="#HL8">Ihre Rechte in Bezug auf Ihre personenbezogenen Daten</a></li>
            <li><a href="#HL9">Wenn Sie uns Ihre personenbezogenen Daten nicht überlassen wollen</a></li>
            <li><a href="#HL10">Wie setzen wir Cookies ein und wo finden Sie weitere Informationen?</a></li>
        </ul>
        <p>Diese Datenschutzerklärung unterliegt fortlaufenden Änderungen. Bitte lesen Sie sich darum diese Datenschutzerklärung regelmäßig durch.</p>
        <p>Wenn Sie Fragen haben, können Sie sich gerne über die im Abschnitt <a href="#Kontakt">Kontaktangaben</a> von GSK angegebenen Möglichkeiten mit uns in Verbindung setzen.</p>
        <h3 id="HL1">Welche personenbezogenen Daten erheben wir?</h3>
        <p>Wenn Sie mit uns über diese Website kommunizieren, erheben wir unter anderem folgende personenbezogenen Daten:</p>
        <p><strong>Daten, die Sie uns zur Verfügung stellen, wie:</strong></p>
        <ul>
            <li>Ihren Namen und Ihre Kontaktdaten,</li>
            <li>E-Mails, die Sie an GSK senden,</li>
            <li>ein Registrierungsformular, wenn Sie sich für ein Webcast-Event registrieren, und</li>
            <li>Ihre Angaben in Befragungen, an denen Sie freiwillig teilnehmen können.</li>
        </ul>
        <p><strong>Technische Informationen, die beispielsweise über Cookies erfasst werden, wie:&nbsp;</strong></p>
        <ul>
            <li>Aktivitätsdaten, beispielsweise wann Sie ein Formblatt auf dieser Website ausgefüllt haben,</li>
            <li>
                Informationen aus Ihren Besuchen auf unserer Website, einschließlich der Art Ihres Browsers und Betriebssystems, Zugriffszeiten, aufgerufene Seiten, angeklickte URLs, Ihre IP-Adresse sowie die Seiten, die Sie vor und nach
                unserer Website besucht haben,
            </li>
            <li>Geräteinformationen, einschließlich der eindeutigen Gerätekennung, des Hardware-Modells, von Betriebssystem und -version sowie Angaben zum Mobilnetz</li>
        </ul>
        <p>
            Zu den personenbezogenen Daten, die wir über das Monitoring anderer Websites erheben, können Ihre öffentlich zugänglichen personenbezogenen Daten gehören, beispielswiese wenn wir den digitalen Austausch auf öffentlichen Plattformen
            mitverfolgen, um mehr darüber zu erfahren, was man über uns oder allgemeiner über die pharmazeutische Industrie sagt. Wir können Informationen über Sie, die uns aus verschiedenen Quellen vorliegen, zusammenfassen.
        </p>
        <p>
            Zu den personenbezogenen Daten, die wir erheben, wenn Sie eine mögliche Nebenwirkung im Zusammenhang mit einem unserer Produkte melden, können beispielsweise Geschlecht, Geburtsdatum und Angaben zu Ihrem Gesundheitszustand gehören.
        </p>
        <h3 id="HL2">Wie verwenden wir Ihre personenbezogenen Daten?</h3>
        <p>Wir können Ihre personenbezogenen Daten zu den folgenden Zwecken verwenden:</p>
        <p><strong>Wir können Ihnen Informationen und Dienstleistungen anbieten wie: </strong></p>
        <ul>
            <li>Online-Veranstaltungen wie Webcast-Events,</li>
            <li>unsere Pressemitteilungen,</li>
            <li>Stellenangebote,</li>
            <li>Finanzergebnisse und</li>
            <li>Tipps und hilfreiche Informationen sowie Marketingmitteilungen zu unseren Produkten und Dienstleistungen. Wir holen für Marketingmitteilungen immer die Einwilligung ein, wenn es gesetzlich vorgeschrieben ist.</li>
        </ul>
        <p><strong>Wir können Sie kontaktieren und mit Ihnen kommunizieren, u.a. um: </strong></p>
        <ul>
            <li>Ihre Fragen zu beantworten (beispielsweise, wenn Sie sich auf eine Stelle beworben haben) und</li>
            <li>wichtige Mitteilungen, wie z.B. Änderungen von Bedingungen, Konditionen und Richtlinien, sowie Updates bereitzustellen, Sicherheitswarnungen und administrative Nachrichten zu senden.</li>
        </ul>
        <p><strong>Durchführung unseres operativen Tagesgeschäfts, auch zu folgenden Zwecken: </strong></p>
        <ul>
            <li>Um auf Ihre Meldung einer möglichen Nebenwirkung im Zusammenhang mit einem unserer Produkte zu antworten und die Sicherheit unserer Produkte zu überwachen.</li>
            <li>Einhaltung des geltenden Rechts, von Bestimmungen und Richtlinien,</li>
            <li>Einhaltung von Anforderungen und Beantwortung von Anfragen von Regulierungsbehörden, Regierungen, Gerichten und Vollzugsbehörden und</li>
            <li>Untersuchungen und Ergreifung von Maßnahmen gegen Nutzer, deren Verhalten rechtswidrig oder für andere oder deren Eigentum schädlich ist.</li>
        </ul>
        <p><strong>Verbesserung unseres operativen Tagesgeschäfts, u. a. zu folgenden Zwecken: </strong></p>
        <ul>
            <li>Interne Zwecke wie Audits, Datenanalyse und Recherchen, um unsere digitalen GSK-Plattformen, Inhalte und Leistungen zur Verfügung zu stellen und verbessern zu können,</li>
            <li>
                zur Überprüfung und Analyse von Trends, Nutzerverhalten und Aktivitäten in Verbindung mit unseren Produkten und Dienstleistungen, um uns Einblick zu geben, welche Teile unserer digitalen Plattformen und Services von größtem
                Interesse sind, um Design und Inhalt unserer Plattformen zu verbessern,
            </li>
            <li>zur Verbesserung unserer Produkte und Dienstleistungen sowie unserer Mitteilungen an Sie und</li>
            <li>(wo zutreffend) um sicherzustellen, dass Ihnen unsere aktuellen Kontaktdaten vorliegen.&nbsp;</li>
        </ul>
        <h3 id="HL3">Auf welcher Grundlage verwenden wir Ihre personenbezogenen Daten?</h3>
        <p>Das Datenschutzgesetz legt eine Reihe von unterschiedlichen Gründen dar, auf die sich eine Firma stützen kann, um Ihre personenbezogenen Daten zu erheben und zu verwenden.</p>
        <p>Wir verwenden Ihre personenbezogenen Daten aus den folgenden Gründen:</p>
        <ul>
            <li>
                <em>Für legitime Geschäftszwecke:</em> Wir verwenden Ihre personenbezogenen Daten, um unsere Kommunikation mit Ihnen besser auf Sie zuzuschneiden und zu personalisieren und um sicherzustellen, dass die Nutzung unserer Produkte
                und Leistungen sich für Sie effizient und effektiv gestaltet. Die Nutzung Ihrer personenbezogenen Daten hilft uns auch dabei, unser Unternehmen zu führen und zu verbessern und Störungen in unserem Serviceangebot für Sie so
                gering wie möglich zu halten.
            </li>
            <li>
                <em>Um einen Vertrag auszuführen, bei dem Sie einer der Vertragspartner sind:</em> Es kann erforderlich sein, Ihre personenbezogenen Daten zu verarbeiten, um Ihrer Anfrage für&nbsp; ein Produkt oder eine Dienstleistung
                nachzukommen.
            </li>
            <li>
                <em>Zur Einhaltung unserer rechtlichen Pflichten und sonstiger Informationsanforderungen:</em> Die Einhaltung rechtlicher Vorschriften, Verordnungen und Richtlinien ist uns wichtig und wir möchten in der Lage sein, diesen und
                den anderen hier dargelegten Anfragen oder Forderungen nach Daten nachzukommen. Sie wirken sich darauf aus, wie wir unser Unternehmen führen, und helfen uns dabei, unsere Produkte und Leistungen so sicher wie möglich zu machen.
            </li>
            <li>
                <em>Ihre Einwilligung:</em> Gelegentlich wird Ihre Einwilligung nötig sein, um Ihre personenbezogenen Daten für einen oder mehrere der vorgenannten Zwecke zu verwenden. Unter „Ihre Rechte in Bezug auf Ihre personenbezogenen
                Daten“ finden Sie Informationen dazu, welche Rechte Sie haben, wenn wir Daten auf der Grundlage Ihrer Einwilligung verarbeiten.
            </li>
        </ul>
        <h3 id="HL4">Wie lange speichern wir Ihre personenbezogenen Daten?</h3>
        <p>
            Grundsätzlich speichern wir Ihre personenbezogenen Daten im Rahmen des gesetzlich vorgeschriebenen Zeitraums und wo es in Verbindung mit schwebenden Rechtsverfahren oder Ermittlungen in Verbindung mit GSK erforderlich ist. Im
            Übrigen speichern wir Ihre personenbezogenen Daten:
        </p>
        <ul>
            <li>so lange sie benötigt werden, um Ihnen Zugang zu den von Ihnen gewünschten Dienstleistungen zu ermöglichen, und</li>
            <li>wenn Sie sich mit einer Frage oder Bitte an uns gewendet haben, so lange es notwendig ist, damit wir Ihre Frage beantworten oder Ihre Bitte erfüllen können.</li>
        </ul>
        <h3 id="HL5">Wir geben Ihre personenbezogenen Daten gelegentlich an Dritte weiter und übertragen Sie ins Ausland</h3>
        <p>Wir können Ihre personenbezogenen Daten an folgende Firmen, Einrichtungen oder Personen weitergeben:</p>
        <ul>
            <li>Verbundene Unternehmen der GSK-Firmengruppe und</li>
            <li>
                die folgenden vertrauenswürdigen Dritten:
                <ul>
                    <li>unsere Vertreter und Zulieferer, darunter Anbieter technologischer Dienstleistungen wie Datenanalyse, Hosting und technischen Support, und</li>
                    <li>unsere Fachberater, Prüfer sowie unsere Geschäftspartner,</li>
                </ul>
            </li>
            <li>Regulierungsbehörden, Regierungen und Vollzugsbehörden sowie</li>
            <li>sonstige Dritte, die in Verbindung mit Umstrukturierungen des ganzen Unternehmens oder seiner Teile stehen.</li>
        </ul>
        <p>
            Ihre personenbezogenen Daten können von GSK, den verbunden Unternehmen von GSK und vertrauenswürdigen Dritten im Ausland verarbeitet werden. Es ist möglich, dass in den Ländern, in die wir Ihre personenbezogenen Daten übertragen,
            kein dem Recht Ihres Heimatlandes gleichwertiger oder gleich wirksamer Schutz personenbezogener Daten gesetzlich vorgeschrieben ist.&nbsp;
        </p>
        <p>
            Wir ergreifen angemessene Maßnahmen, um sicherzustellen, dass Ihre personenbezogenen Daten bei einer Übertragung ins Ausland weiter gemäß den geltenden Gesetzen zum Schutz von Daten und Persönlichkeitsrechten geschützt und sicher
            sind. Dazu gehören Datenübertragungsvereinbarungen, die Standarddatenschutzklauseln umsetzen, oder die verbindlichen Unternehmensregeln von GSK.&nbsp;Hier finden Sie weitere Informationen zu den
            <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en" target="_blank">Datenübertragungsvereinbarungen</a>. Es gibt auch zusätzliche
            Informationen zu den <a href="https://www.gsk.com/media/4910/binding-corporate-rules-policy_german.pdf" target="_blank">verbindlichen Unternehmensregeln</a> von GSK.
        </p>
        <h3 id="HL6">Schutz Ihrer personenbezogenen Daten</h3>
        <p>
            Wir verwenden verschiedene Sicherheitsverfahren und -technologien zum Schutz Ihrer personenbezogenen Daten vor unbefugtem Zugriff, Nutzung, Offenlegung, Änderung oder Löschung, die den geltenden Gesetzen zum Schutz von Daten und
            Persönlichkeitsrechten entsprechen. Wenn wir beispielsweise Ihre personenbezogenen Daten an externe Zulieferer weitergeben, können wir eine schriftliche Vereinbarung treffen, die den Zulieferer verpflichtet, Ihre Daten vertraulich
            zu behandeln, und angemessene Sicherheitsvorkehrungen treffen, damit Ihre Daten geschützt sind.
        </p>
        <p>Die Übermittlung von Daten an uns über das Internet oder ein Mobilfunknetz ist aber nicht absolut sicher; jede Übermittlung erfolgt auf Ihr eigenes Risiko.</p>
        <h3 id="HL7">Websites, die uns nicht gehören und die sich unserer Kontrolle entziehen</h3>
        <p>
            Gelegentlich stellen wir Links zu Websites oder mobilen Apps zur Verfügung, die uns nicht gehören und die sich unserer Kontrolle entziehen. Die vorliegende Datenschutzerklärung ist nicht für diese Websites gültig. Falls Sie sich zur
            Nutzung dieser Websites entschließen, sollten Sie sicherstellen, dass Sie die Haftungs- und Datenschutzerklärungen der jeweiligen Websites oder Apps für Mobilgeräte, die Sie besuchen, prüfen, um sich mit den dort geltenden
            Datenschutzpraktiken vertraut zu machen.
        </p>
        <h3 id="HL8">Ihre Rechte in Bezug auf Ihre personenbezogenen Daten</h3>
        <p>Aufgrund des Datenschutzgesetzes haben Sie eine Reihe von Rechten bezüglich Ihrer personenbezogenen Daten.</p>
        <p>Sie können folgende Rechte haben:</p>
        <ul>
            <li>Sie können GSK um Zugang zu bei GSK vorgehaltenen Daten über Sie bitten,</li>
            <li>Sie können verlangen, dass Ihre personenbezogenen Daten korrigiert und/oder gelöscht werden,</li>
            <li>Sie können verlangen, dass die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt wird, oder der Verarbeitung widersprechen,</li>
            <li>Sie können die Einwilligung zur Verarbeitung Ihrer personenbezogenen Daten widerrufen (sofern GSK Ihre personenbezogenen Daten auf der Grundlage Ihrer Einwilligung verarbeitet),</li>
            <li>Sie können darum bitten, dass Sie die personenbezogenen Daten, die Sie GSK überlassen haben, selbst erhalten oder dass diese in maschinenlesbarer Form einer anderen Einrichtung übergeben werden, und</li>
            <li>Sie können Beschwerde bei der örtlichen Datenschutzbehörde einlegen, wenn Ihre Datenschutzrechte verletzt wurden oder Sie infolge einer rechtswidrigen Verarbeitung Ihrer personenbezogenen Daten einen Schaden erlitten haben.</li>
        </ul>
        <p>Wenn Sie Ihre Rechte wahrnehmen wollen, teilen Sie uns das bitte mit, indem Sie sich wie unten im Abschnitt zu den Kontaktangaben von GSK beschrieben mit uns in Verbindung setzen.</p>
        <h3 id="HL9">Wenn Sie uns Ihre personenbezogenen Daten nicht überlassen wollen</h3>
        <p>Dort, wo Sie die Option haben, Ihre personenbezogenen Daten mit uns zu teilen, können Sie sich auch entscheiden, dies nicht zu tun.</p>
        <p>
            Wenn Sie der Verarbeitung Ihrer personenbezogenen Daten widersprechen oder wenn Sie Ihre Einwilligung zur Verarbeitung bereits erteilt haben und sich später entscheiden, die Einwilligung zu widerrufen, werden wir diesem Wunsch gemäß
            unseren rechtlichen Pflichten nachkommen. Das könnte bedeuten, dass wir die erforderlichen Maßnahmen nicht durchführen können, um die oben im Abschnitt „Wie verwenden wir Ihre personenbezogenen Daten?“ beschriebenen Zwecke zu
            erreichen, oder dass Sie die von uns angebotenen Dienstleistungen und Produkte nicht nutzen können.
        </p>
        <h3 id="HL10">Cookies und andere Technologien</h3>
        <p>Diese Website verwendet eine als „Cookies“ bezeichnete Technologie, die es ihr erlaubt, Sie wiederzuerkennen und Sie persönlich anzusprechen. Sie können die Cookies annehmen oder ablehnen.</p>
        <p>
            Wenn Sie sich entscheiden, Cookies abzulehnen, funktionieren möglicherweise nicht alle Elemente dieser Website wie vorgesehen. Dadurch können Ihre Erfahrungen mit der Website beeinträchtigt werden. Weitere Informationen dazu, wie
            wir Cookies verwenden, finden Sie unter unseren <a href="/de-de/cookie/" title="Cookie">Cookie-Richtlinien</a>.
        </p>
        <h3>Datenschutzerklärung für die Nutzung von Adobe Analytics (Omniture)</h3>
        <p>
            Auf dieser Internetseite kommt außerdem Adobe Analytics (Omniture), ein Webanalysedienst der Adobe Systems Software Ireland Limited („Adobe“), zum Einsatz. Adobe Analytics verwendet sog. “Cookies”, Textdateien, die auf Ihrem
            Computer gespeichert werden und die eine Analyse der Benutzung der Internetseite durch Sie ermöglichen.
        </p>
        <p>
            Werden die durch das Cookie erzeugten Informationen über die Benutzung der Internetseite an einen Server von Adobe übermittelt, dann ist durch die Einstellungen gewährleistet, dass die IP-Adresse vor der Geolokalisierung
            anonymisiert und vor Speicherung durch eine generische IP-Adresse ersetzt wird. Im Auftrag des Betreibers dieser Internetseite wird Adobe diese Informationen benutzen, um die Verwendung der Internetseite durch die Nutzer
            auszuwerten, um Reports über die Aktivitäten auf der Internetseite zusammenzustellen und um weitere verbundene Dienstleistungen zu erbringen. Die im Rahmen von Adobe Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit
            anderen Daten von Adobe zusammengeführt. Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern. Wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht
            sämtliche Funktionen dieser Internetseite vollumfänglich nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Internetseite bezogenen Daten (inkl. Ihrer IP-Adresse) an Adobe
            sowie die Verarbeitung dieser Daten durch Adobe verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plug-In herunterladen und installieren:
            <a href="http://www.adobe.com/de/privacy/opt-out.html" target="_blank">http://www.adobe.com/de/privacy/opt-out.html</a>
        </p>
        <h3>Datenschutzerklärung für die Nutzung von Facebook Remarketing (Facebook Ads)</h3>
        <p>
            Diese Internetseite nutzt die Remarketing-Funktionalitäten von Facebook, Anbieter Facebook Inc., 1 Hacker Way, Menlo Park, California 94025, USA, zur Ausspielung personenbezogener und zielgruppenspezifischer Werbung im
            Facebook-Werbenetzwerk (Facebook Ads). Weitere Informationen über Umfang und Zweck der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook sowie Ihre Einstellungsmöglichkeiten zum Schutz Ihrer
            Privatsphäre entnehmen Sie bitte den Datenschutzbedingungen von Facebook. Falls Sie keine Datenerfassung zur Ausspielung von personenbezogener und zielgruppenspezifischer Werbung durch Facebook Ads wünschen, können Sie diesem
            Vorgehen nachfolgender Anleitung wiedersprechen: <a href="https://de-de.facebook.com/help/568137493302217" target="_blank">https://de-de.facebook.com/help/568137493302217</a>.
        </p>
        <h3>Datenschutzerklärung für die Nutzung von Google Analytics und Google Remarketing (AdWords)</h3>
        <p>
            Diese Internetseite nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Inc. 1600 Amphitheatre Parkway Mountain View, CA 94043, USA. Google Analytics verwendet sog. "Cookies". Das sind Textdateien, die
            auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Internetseite durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Internetseite werden in der Regel an einen
            Server von Google in den USA übertragen und dort gespeichert.
        </p>
        <p>
            Wir nutzen die Funktion zur IP-Anonymisierung von Google Analytics auf dieser Internetseite. Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union, oder in anderen Vertragsstaaten des Abkommens
            über den Europäischen Wirtschaftsraum, zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Internetseite wird Google
            diese Informationen benutzen, um Ihre Nutzung der Internetseite auszuwerten, um Reports über die Internetseitenaktivitäten zusammenzustellen und um weitere mit der Internetseitennutzung und der Internetnutzung verbundene
            Dienstleistungen gegenüber dem Internetseitenbetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
        </p>
        <p>
            Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Internetseite
            vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Internetseite bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser
            Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plug-In herunterladen und installieren:
            <a href="http://tools.google.com/dlpage/gaoptout?hl=de" target="_blank">http://tools.google.com/dlpage/gaoptout?hl=de</a>
        </p>
        <p>
            Wir nutzen Google Analytics zudem dazu, Daten aus dem Google AdWords-Cookie und dem DoubleClick-Cookie auszuwerten, um im Anschluss dem Besucher der Internetseite personenbezogene und zielgruppenspezifische Werbung mittels
            Retargeting/Remarketing auf Drittseiten auszuspielen. Sie können diesem Vorgehen wiedersprechen indem sie den Anzeigen-Manager (<a href="http://www.google.com/settings/ads" target="_blank">http://www.google.com/settings/ads</a>) von
            Google aufrufen und dort einer personalisierten Ausspielung von Werbeanzeigen wiedersprechen.
        </p>
        <h3 id="Kontakt">Kontaktangaben</h3>
        <p><strong>GlaxoSmithKline GmbH &amp; Co. KG</strong> <br />Prinzregentenplatz 9 <br />81675 München</p>
        <p><strong>Geschäftsführung</strong>: Jean-Bernard Siméon (Vors.)</p>
        <p><strong>Tel.</strong>: 0800 / 122 33 55<br /><strong>E-Mail</strong>: <a href="mailto:produkt.info@gsk.com">produkt.info[at]gsk.com</a><br /><strong>Internet</strong>: http://de.gsk.com</p>
        <p><strong>Kontakt zum Datenschutzbeauftragten</strong>: <a href="mailto:DE.Datenschutz-GSK-Pharma@gsk.com">Datenschutz GSK Pharma </a></p>
        <p>
            Bitte verwenden Sie die obenstehenden Kontaktangaben und richten Sie alle Fragen, Anmerkungen und Anfragen zu dieser Datenschutzerklärung an Ihre GSK Niederlassung vor Ort. Wenn Sie sich mit uns in Verbindung setzen, geben Sie bitte
            den Namen der von Ihnen besuchten Website und Ihre Kontaktdaten an.
        </p>
        <p>Kontaktangaben zu anderen GSK Standorten erhalten Sie unter <a href="http://www.gsk.com/en-gb/contact-us/worldwide/" target="_blank">http://www.gsk.com/en-gb/contact-us/worldwide/</a>.</p>
        <h3>Verantwortliche für die Datenverarbeitung</h3>
        <p>GlaxoSmithKline Services Unlimited und das für diese Website zuständige lokale verbundene Unternehmen von GlaxoSmithKline sind für die Verarbeitung Ihrer personenbezogenen Daten verantwortlich.</p>
        <p>Hier finden Sie eine <a href="https://www.gsk.com/en-gb/contact-us/privacy-contact-information/" target="_blank">Liste</a> der verbundenen Unternehmen von GSK und die Kontaktangaben zu dem EU-Datenschutzbeauftragten von GSK.</p>
    </div>
</div>