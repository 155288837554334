import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, CanActivate, RouterStateSnapshot } from "@angular/router";
import { OAuthStorageAdapter } from "./OAuthStorageAdapter";
import { AuthenticationService } from "./services/authentication.service";

const REDIRECTTO = "/login";

@Injectable()
export class AuthenticationGuard implements CanActivate {
    constructor(private router: Router, private oauthStorageAdapter: OAuthStorageAdapter, private oauthService: AuthenticationService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const organizationKey = this.oauthStorageAdapter.getCurrentOrganizationKey();
        if (!organizationKey) {
            this.router.navigate([REDIRECTTO]);
            return false;
        }

        return this.oauthStorageAdapter
            .getCurrentOAuthResult()
            .ifNothing(() => this.router.navigate([REDIRECTTO]))
            .ifJust((auth) => {
                if (!auth.accessTokenExpiresAt) {
                    this.router.navigate([REDIRECTTO]);
                    return false;
                }

                // const tokenExpiresAt = new Date(auth.accessTokenExpiresAt);
                // if (tokenExpiresAt.getTime() <= Date.now()) {
                //     this.router.navigate([REDIRECTTO]);
                //     return false;
                // }

                // Get user profile, ignore result. If user is not authenticated anymore, the http error interceptor will redirect to login page
                this.oauthService.profile().subscribe((result) => {});

                return true;
            })
            .isJust();
    }
}
