import { Component, AfterViewInit, NgZone, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { LosTrackingService } from "src/app/services/lostracking.service";
import { IOrganizationSettings, OAuthStorageAdapter } from "../../OAuthStorageAdapter";
import { BaseComponent } from "../BaseComponent";

declare const embedpano: any;
declare const clicktracker: any;

@Component({
    selector: "app-reception",
    templateUrl: "./reception.component.html",
    styleUrls: ["./reception.component.scss"]
})
export class ReceptionComponent extends BaseComponent implements AfterViewInit, OnDestroy {
    public agendaVisible = false;
    public helpVisible = false;
    private organizationSettings: IOrganizationSettings;

    constructor(private _ngZone: NgZone, private router: Router, private oauthStorageAdapter: OAuthStorageAdapter, private losTrackingService: LosTrackingService) {
        super();

        window["receptionComponentRef"] = { component: this, zone: _ngZone };

        this.organizationSettings = this.oauthStorageAdapter.getOrganizationSettings();
        if (this.organizationSettings.clickTracking) {
            clicktracker.setup(this.organizationSettings?.clickTracking?.token, this.organizationSettings?.clickTracking?.endpoint, this.organizationSettings?.clickTracking?.tenantId);
        }
    }

    // Usage in KRPano => onclick="js(gotoLectureHall())"
    gotoLectureHall() {
        this.router.navigate(["/lecturehall"]);
    }

    // Usage in KRPano => onclick="jscall(clickSpot('BoC - SGO 2020'));"
    clickSpot(elementName) {
        clicktracker.click(elementName);
    }

    ngAfterViewInit() {
        embedpano({
            swf: this.organizationSettings.panoSwf,
            xml: this.organizationSettings.panoXml,
            target: "reception-pano",
            html5: "auto",
            mobilescale: 1.0,
            passQueryParameters: true
        });

        const auth = this.oauthStorageAdapter.getCurrentOAuthResult().orDefault(null);
        const jwt = auth.jwt ?? "";

        this.subscriptions.push(
            this.losTrackingService.cloudflareLookup().subscribe(
                (result) => {
                    const data: any = result
                        .trim()
                        .split("\n")
                        .reduce((obj, pair: any) => {
                            pair = pair.split("=");
                            return (obj[pair[0]] = pair[1]), obj;
                        }, {});

                    this.losTrackingService.setupSocketConnection(jwt, "reception", data?.loc);
                },
                (err) => {
                    this.losTrackingService.setupSocketConnection(jwt, "reception", undefined);
                }
            )
        );
    }

    toggleAgenda() {
        this.agendaVisible = !this.agendaVisible;
    }

    toggleHelp() {
        this.helpVisible = !this.helpVisible;
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();

        this.losTrackingService.closeSocketConnection();
    }
}
