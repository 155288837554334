<div class="container">
    <header>
        <img src="assets/img/header.png" />
    </header>
    <article>
        <h1>Impfstoff-Gesamttagung 2021<br />29.09. & 30.09.2021</h1>
        <p>Liebe Kollegin, lieber Kollege,</p>
        <p>vielen Dank, dass Du dich für unsere Impfstoff-Gesamttagung registrieren möchtest.</p> 
        <p>Bitte gib unten deine GSK E-Mail Adresse und ein individuelles Passwort ein. Nach der Registrierung erhältst Du eine Bestätigung, den Link zum Event senden wir Dir wenige Tage vor dem Event via E-Mail.</p>
        <p>Gerne möchten wir Dir noch einige Informationen für die Impfstoff-Gesamttagung mit auf den Weg geben, siehe weiter unten.</p>
        <p>Wir freuen uns auf eine kreative Zeit mit Dir!</p>
        
        <p>
            Viele Grüße,<br/>
            Dein Planungsteam der Impfstoff-Gesamttagung<br/>
            Stephanie&nbsp;M., Christa&nbsp;W., Tanja&nbsp;L., Claudia&nbsp;F., Stephanie&nbsp;H., Roland&nbsp;G., Andreas&nbsp;R., Emanuela&nbsp;N., Birgit&nbsp;B., Simone&nbsp;B.<br/>
        </p>
        <div class="app-registration" *ngIf="showRegistrationFormView">
            <form name="form" #f="ngForm">
                <div class="cross-validation">
                    <!-- <div class="form-group">
                <input id="firstName" name="firstname" #firstNameInput="ngModel" [(ngModel)]="registration.firstname" type="text" class="form-control" required />
                <label>{{ "registration.labels.first_name" | translate }}</label>
                <div class="validation-error alert alert-danger" *ngIf="firstNameInput?.errors?.required && (firstNameInput.dirty || firstNameInput.touched)">
                    <p>{{ 'registration.validation.first_name' | translate }}</p>
                </div>
            </div>

            <div class="form-group">
                <input id="lastName" name="lastName" #lastNameInput="ngModel" [(ngModel)]="registration.lastname" type="text" class="form-control" required />
                <label>{{ "registration.labels.last_name" | translate }}</label>
                <i class="form-group__bar"></i>
                <div class="validation-error alert alert-danger" *ngIf="lastNameInput.errors?.required && (lastNameInput.dirty || lastNameInput.touched)">
                    <p>{{ 'registration.validation.last_name' | translate }}</p>
                </div>
            </div> -->

                    <div class="form-group">
                        <input id="email" name="email" #emailInput="ngModel" [(ngModel)]="registration.email" type="email" class="form-control" required email />
                        <label>{{ "registration.labels.email" | translate }}</label>
                        <div class="validation-error alert alert-danger" *ngIf="emailInput.invalid && (emailInput.dirty || emailInput.touched)">
                            <p>{{ "registration.validation.email" | translate }}</p>
                        </div>
                    </div>

                    <div class="form-group">
                        <input id="password" name="password" #passwordInput="ngModel" [(ngModel)]="registration.password" type="password" class="form-control" required />
                        <label>{{ "registration.labels.password" | translate }}</label>
                        <div class="validation-error alert alert-danger" *ngIf="passwordInput.errors?.required && (passwordInput.dirty || passwordInput.touched)">
                            <p>{{ "registration.validation.password" | translate }}</p>
                        </div>
                    </div>

                    <div class="form-group">
                        <input id="passwordConfirm" #passwordConfirm="ngModel" name="passwordConfirm" [(ngModel)]="registration.passwordConfirm" type="password" class="form-control" required />
                        <label>{{ "registration.labels.password_confirm" | translate }}</label>
                        <div class="validation-error alert alert-danger" *ngIf="!(registration.passwordConfirm === registration.password)">
                            <p>{{ "registration.validation.password_confirm_mismatch" | translate }}</p>
                        </div>
                    </div>

                    <div class="form-group">
                        <textarea id="comment" name="comment" [(ngModel)]="registration.comment" class="form-control"></textarea>
                        <label>{{ "registration.labels.comment" | translate }}</label>
                    </div>

                    <div class="form-group">
                        <select id="participation" name="participation" [(ngModel)]="registration.misc.participation" class="form-control">
                            <option value="yes">Ja</option>
                            <option value="no">Nein</option>
                        </select>
                        <label>{{ "registration.labels.participation" | translate }}</label>
                    </div>

                    <div class="form-group form-group--float form-group--centered">
                        <input id="agbAccepted" #agbAcceptedInput="ngModel" name="agbAccepted" [(ngModel)]="registration.agbAccepted" type="checkbox" required />
                        <label id="agbAcceptLabel"
                            >{{ "registration.labels.accept" | translate }} <a target="_blank" href="/#/data_legal">{{ "registration.labels.terms" | translate }}</a></label
                        >
                        <div class="validation-error alert alert-danger" *ngIf="agbAcceptedInput.errors?.required && (agbAcceptedInput.dirty || agbAcceptedInput.touched)">
                            <p>{{ "registration.validation.terms_accepted" | translate }}</p>
                        </div>
                    </div>

                    <button class="btn btn-primary btn-block" type="submit" (click)="register()" [disabled]="f.invalid">
                        {{ "registration.buttons.register" | translate }}
                    </button>
                </div>
            </form>
        </div>

        <div class="app-registration-successful" *ngIf="showRegistrationSuccessfulView">
            <h4>{{ "registration.titles.registration_done" | translate }}</h4>
            <p>{{ "registration.paragraphs.registration_successful" | translate }}</p>
        </div>

        <section class="icons">
            <div class="row">
                <div class="col-sm-6 col-lg d-grid justify-content-center">
                    <a href="assets/GSK/Impftagung-Agenda.pdf" target="_blank">
                        <p><mat-icon>assignment</mat-icon></p>
                        <p>Agenda</p>
                    </a>
                </div>
                <div class="col-sm-6 col-lg d-grid justify-content-center">
                    <a href="assets/GSK/Tag-1-Impftagung.ics">
                        <p><mat-icon>date_range</mat-icon></p>
                        <p>Tag 1</p>
                    </a>
                </div>
                <div class="col-sm-6 col-lg d-grid justify-content-center">
                    <a href="assets/GSK/Tag-2-Impftagung.ics">
                        <p><mat-icon>date_range</mat-icon></p>
                        <p>Tag 2</p>
                    </a>
                </div>
            </div>
        </section>

        <section>
            <h2>Bilder:</h2>
            <p>Wir rufen Dich dazu auf, Deine schönsten, kreativsten und lustigsten Homeoffice-Momente 2020 / 2021 mit uns zu teilen:</p>
            <ol>
                <li>Schicke uns ein kreatives Bild oder gestalte Deine eigene Collage</li>
                <li>
                    Lade dieses Bild bitte unter diesem <a href="https://uploads.luxav.de/index.php/s/Hp5xrCAdqKTpkRi">Link</a> hoch
                </li>
                <li>
                    Bei Problemen wende Dich bitte an <a href="mailto:gsk@mci-group.com">gsk@mci-group.com</a> mit dem Betreff Impfstoff-Gesamttagung
                </li>
            </ol>
        </section>

        <section>
            <h2>iPad-Nutzer:</h2>
            <p>Auf dem iPad sind alle aktuellen Updates vorhanden und einem problemlosen Start am 29.09. steht nichts im Wege</p>
        </section>

        <section>
            <h2>Laptop-Nutzer:</h2>
            <p>Wir bitten Euch einen der folgenden Browser für den Zugang zum Event zu benutzen: Edge, Chrome oder Firefox</p>
        </section>

        <section>
            <h2>Servicekontakt:</h2>
            <p>Bei technischen Schwierigkeiten kannst Du Dich jederzeit an unseren Support wenden:</p>

            <p>Tel.: 02244 – 87 84 811</p>
            <p>E-Mail: <a href="mailto:support@infill.com">support@infill.com</a></p>
        </section>

        <section>
            <h2>Organisationsteam MCI:</h2>
            <p>Christina Behring</p>

            <p>Tel.: 089 – 54 90 96 18</p>
            <p>E-Mail: <a href="mailto:christina.behring@mci-group.com">christina.behring@mci-group.com</a></p>
        </section>

        <p class="footnote">
            *Die erhobenen, personenbezogenen Daten dienen ausschließlich der Teilnehmerverwaltung (Veranstalter und Partner) in Zusammenhang mit dieser Veranstaltung. Weitere Informationen findest Du hier:
            <a href="https://de.gsk.com/de-de/datenschutzerklaerung/" target="_blank">https://de.gsk.com/de-de/datenschutzerklaerung/</a>
        </p>
    </article>
    <footer>
        <br/>
    </footer>
</div>
