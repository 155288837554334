<div id="contentContainer">
    <div class="container">
        
      

        Lieber User,<br/>
        der Internet Explorer wird leider von der Plattform nicht unterstützt.<br/>
        Bitte benutzen Sie einen der folgenden Browser:

        <ul>
            <li>Microsoft EDGE</li>
            <li>Google Chrome</li>
            <li>Mozilla Firefox</li>
            <li>Apple Safari</li>
        </ul>

        Vielen Dank.<br/>
        Beste Grüße,<br/><br/>
        Ihr GSK-Team


    </div>

</div>