<div id="reception-pano"></div>

<button class="agenda-btn gsk-btn" mat-raised-button (click)="toggleAgenda()" *ngIf="!agendaVisible">
    {{ "lecturehall.buttons.agendaToggle" | translate }}
</button>

<div id="agendaBackground" *ngIf="agendaVisible" (click)="toggleAgenda()"></div>

<div id="agendaFrame" *ngIf="agendaVisible">
    <iframe src="https://online.fliphtml5.com/elkzy/flcy/"></iframe>
</div>

<button *ngIf="agendaVisible" id="agendaFrameCloseButton" (click)="toggleAgenda()" title="Close">
    <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24"><path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path></svg>
</button>

<button class="help-btn gsk-btn" mat-raised-button (click)="toggleHelp()" *ngIf="!helpVisible">
    {{ "lecturehall.buttons.helpToggle" | translate }}
</button>

<div id="helpBackground" *ngIf="helpVisible" (click)="toggleHelp()"></div>

<div id="helpFrame" *ngIf="helpVisible">
    <div>
        <br/>
        <b>Servicekontakt:</b><br/>
        Tel.: 02244 – 87 84 811 <br/>
        E-Mail: <a href="mailto:support@infill.com">support@infill.com</a><br/><br/>

        <b>Organisationsteam MCI:</b><br/>
        Christina Behring <br/>
        Tel.: 089 – 54 90 96 18 <br/>
        E-Mail: <a href="mailto:christina.behring@mci-group.com">christina.behring@mci-group.com</a> <br/>
        E-Mail: <a href="mailto:gsk@mci-group.com">gsk@mci-group.com</a><br/>
    </div>
</div>

<button *ngIf="helpVisible" id="helpFrameCloseButton" (click)="toggleHelp()" title="Close">
    <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24"><path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path></svg>
</button>
