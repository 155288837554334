import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import * as moment from "moment";
import { OrganizationBaseComponent } from "../OrganizationBaseComponent";
import { AuthenticationService } from "../../services/authentication.service";
import { ActivatedRoute, Router } from "@angular/router";
import { IOAuthLoginResult } from "../../models/IOAuthLoginResult";
import { OAuthStorageAdapter } from "../../OAuthStorageAdapter";
import { environment } from "src/environments/environment";
@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"]
})
export class LoginComponent extends OrganizationBaseComponent implements OnInit {
    public loginEmail: string = "";
    public loginPassword: string = "";
    public disableLogin = false;

    @ViewChild("loginPasswordField", { static: false }) loginPasswordField: ElementRef;

    constructor(private authenticationService: AuthenticationService, protected router: Router, private oauthStorageAdapter: OAuthStorageAdapter, protected route: ActivatedRoute) {
        super(router, route);

        const blockLoginUntil = moment("2021-09-29 08:30:00 +0200", "YYYY-MM-DD HH:mm:ss ZZ");
        const now = moment();

        if (now.isBefore(blockLoginUntil) && environment.environmentName === "production") {
            this.disableLogin = true;
        }
    }

    onEnterEmail(): void {
        this.loginPasswordField.nativeElement.focus();
    }

    onEnterPassword(): void {
        this.login();
    }

    login(): void {
        this.subscriptions.push(
            this.authenticationService.login(this.loginEmail, this.loginPassword, this.organizationKey).subscribe((result: IOAuthLoginResult) => {
                this.oauthStorageAdapter.setCurrentOauthResult(result);
                this.oauthStorageAdapter.setCurrentOrganizationKey(this.organizationKey);
                this.router.navigate(["/reception"]);
            })
        );
    }
}
