import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

interface AlertMessage {
    title: string;
    description: string;
    type: string;
}

@Component({
    templateUrl: "./alert-dialog.component.html",
    styleUrls: ["./alert-dialog.component.scss"]
})
export class AlertDialogComponent {
    public cssClass: string = "";
    public alertMessage = {
        title: "",
        description: "",
        type: ""
    };

    constructor(@Inject(MAT_DIALOG_DATA) public message: AlertMessage) {}

    ngOnInit(): void {
        this.cssClass = this.message.type === "error" ? "alert-danger" : "alert-primary";
        this.alertMessage = this.message;
    }
}
