<!-- Alerts -->
<div class="container" id="alertContainer">
    <app-alert></app-alert>
</div>
<!-- Alerts -->
<router-outlet></router-outlet>
<!-- Footer -->
<footer class="page-footer">
    <!-- Copyright -->
    <div class="footer-copyright text-center py-3">
        © 2021 Copyright:
        <a href="https://gskpro.com/en-us/"> www.gskpro.com</a>
        <br />
        <div class="footer-links">
            <a href="#/imprint">{{ "footer.links.imprint" | translate }}</a> |
            <a href="#/data_legal">{{ "footer.links.privacy" | translate }}</a>
        </div>
    </div>
    <!-- Copyright -->
</footer>
<!-- Footer -->
