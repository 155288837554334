import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { AlertService } from "./services/alert.service";
import { OAuthStorageAdapter } from "./OAuthStorageAdapter";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private alertService: AlertService, private router: Router, private oauthStorageAdapter: OAuthStorageAdapter) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                this.alertService.dialogError(error.error.message, error.error.code);

                if (error.error.code === "authentication_required") {
                    const organizationKey = this.oauthStorageAdapter.getCurrentOrganizationKey();
                    OAuthStorageAdapter.clearStorage();
                    this.router.navigate(["/login"]);
                }

                return throwError(error);
            })
        );
    }
}
