import { Injectable } from "@angular/core";
import { Router, NavigationStart, NavigationEnd } from "@angular/router";

import { AlertMessage } from "../models/AlertMessage";
import { Observable, Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { BaseComponent } from "../components/BaseComponent";

enum AlertState {
    success = "success",
    error = "error"
}

@Injectable({
    providedIn: "root"
})
export class AlertService extends BaseComponent {
    private subject = new Subject<AlertMessage>();
    private dialogSubject = new Subject<AlertMessage>();

    constructor(private router: Router, private translateService: TranslateService) {
        super();
        this.subscriptions.push(
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationStart || event instanceof NavigationEnd) {
                    this.clear();
                }
            })
        );
    }

    success(message: string, code: string) {
        this.pushMessage(message, code, AlertState.success);
    }

    error(message: string, code: string) {
        this.pushMessage(message, code, AlertState.error);
    }

    dialogError(message: string, code: string) {
        this.dialogSubject.next({
            title: this.translateService.instant("global.alert.error"),
            description: this.translateService.instant("global.api.errors." + code),
            type: AlertState.error
        });
    }

    private pushMessage(message: string, code: string, type: string) {
        const title = type.localeCompare("success") === 0 ? this.translateService.instant("global.alert.success") : this.translateService.instant("global.alert.error");
        const translationKey = "global.api.errors." + code;
        const localizedMessage = this.translateService.instant(translationKey) === translationKey ? translationKey : this.translateService.instant(translationKey);

        const alertMessage = {
            title: title,
            description: localizedMessage,
            type: type
        };
        this.subject.next(alertMessage);
    }

    getAlert(): Observable<AlertMessage> {
        return this.subject.asObservable();
    }

    getDialogAlert(): Observable<AlertMessage> {
        return this.dialogSubject.asObservable();
    }

    public clear(): void {
        this.subject.next();
    }
}
