import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import { IRegistration } from "../../models/IRegistration";
import { IRegistrationConfirmation } from "../../models/IRegistrationConfirmation";
import { environment } from "../../../environments/environment";
import { RegistrationService } from "../../services/registration.service";
import { OrganizationBaseComponent } from "../OrganizationBaseComponent";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: "app-registration",
    templateUrl: "./registration.component.html",
    styleUrls: ["./registration.component.scss"]
})
export class RegistrationComponent extends OrganizationBaseComponent implements OnInit {
    public registration: IRegistration = {
        email: "",
        // firstname: "",
        // lastname: "",
        password: "",
        passwordConfirm: "",
        comment: "",
        agbAccepted: false,
        language: environment.defaultLanguage,
        misc: {
            participation: "yes"
        },
        organizationKey: ""
    };

    public showRegistrationFormView: boolean = true;
    public showRegistrationSuccessfulView: boolean = false;

    constructor(private registrationService: RegistrationService, protected route: ActivatedRoute, protected router: Router) {
        super(router, route);

        const redirectToLoginAfter = moment("2021-09-29 09:00:00 +0200", "YYYY-MM-DD HH:mm:ss ZZ");
        const now = moment();

        if (now.isAfter(redirectToLoginAfter) && environment.environmentName === "production") {
            this.router.navigate(["/login"]);
        }
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.subscriptions.push(
            this.route.queryParamMap.subscribe((params) => {
                const token = params.get("token");
                if (token) {
                    this.confirmRegistration(token);
                }
            })
        );

        this.registration.organizationKey = this.organizationKey;
    }

    register(): void {
        this.subscriptions.push(
            this.registrationService.register(this.registration).subscribe((result: any) => {
                this.showRegistrationFormView = false;
                this.showRegistrationSuccessfulView = true;
            })
        );
    }

    confirmRegistration(token: string): void {
        const registrationConfirmation: IRegistrationConfirmation = {
            token: token,
            language: "en"
        };
        this.subscriptions.push(
            this.registrationService.confirmRegistration(registrationConfirmation).subscribe((result) => {
                this.router.navigate(["/login"]);
            })
        );
    }
}
