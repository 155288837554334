import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment";
import { IOrganizationSettings, OAuthStorageAdapter } from "../../OAuthStorageAdapter";
import { environment } from "../../../environments/environment";
import { BaseComponent } from "../BaseComponent";

@Component({
    selector: "app-waitingroom",
    templateUrl: "./waitingroom.component.html",
    styleUrls: ["./waitingroom.component.scss"]
})
export class WaitingRoomComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    public backgroundImage = "GSK_Auditorium_v2_01.jpg";
    public refreshInterval = null;
    public organizationSettings: IOrganizationSettings;

    private webinarStart;

    constructor(private router: Router, private oauthStorageAdapter: OAuthStorageAdapter) {
        super();

        this.organizationSettings = this.oauthStorageAdapter.getOrganizationSettings();

        this.webinarStart = moment(this.organizationSettings.webinarStart, environment.webinarTimeformat);
        const now = moment();

        // GMP-22: go to lecture hall if webinar did already start
        if (this.organizationSettings.waitingRoomFeature && now.isAfter(this.webinarStart)) {
            this.router.navigate(["/lecturehall"]);
            return;
        }
    }

    ngOnInit(): void {
        this.refreshInterval = setInterval(() => {
            this.refreshBackground();
        }, 5000); // check ever 5 seconds if we need to change the background image
        this.refreshBackground();
    }

    refreshBackground() {
        const now = moment();
        let timeDiffInMinutes = Math.ceil(moment.duration(this.webinarStart.diff(now)).asMinutes());
        if (timeDiffInMinutes <= 0) {
            this.router.navigate(["/lecturehall"]);
        }
        if (timeDiffInMinutes > 21) {
            timeDiffInMinutes = 21;
        }

        const imageNumber = (timeDiffInMinutes - 21) * -1 + 1;
        this.backgroundImage = "GSK_Auditorium_v2_" + this.pad(imageNumber, 2) + ".jpg";
    }

    ngAfterViewInit(): void {}

    ngOnDestroy(): void {
        super.ngOnDestroy();

        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
        }
    }

    private pad(num: number, size: number): string {
        let s = num + "";
        while (s.length < size) {
            s = "0" + s;
        }
        return s;
    }
}
