// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    environmentName: "test",
    version: require("../../package.json").version,
    httpRequestTimeoutSeconds: 120,
    utcDateFormat: "yyyy-MM-dd HH:mm:ss",
    baseAPI: "https://api.gsk.infillqa.com/api/v1/",
    oauthBaseAPI: "https://api.gsk.infillqa.com/api/v1/auth/",
    registrationsAPI: "https://api.gsk.infillqa.com/api/v1/registration/",
    resetPasswordAPI: "https://api.gsk.infillqa.com/api/v1/resetpassword/",
    defaultLanguage: "en",
    webinarTimeformat: "YYYY-MM-DD HH:mm:ss ZZ",
    logErrorInConsole: true,
    organizations: [
        {
            key: "vd2021q3",
            id3qsdn: "b9db26f2-7bfe-11ea-97a4-002590c750be",
            webinarStart: "2020-10-19 15:19:00 +0200",
            waitingRoomFeature: false,
            panoSwf: "assets/panos/pano-2021/app_assets/assets/js/krpano.swf",
            panoXml: "assets/panos/pano-2021/app_assets/gsk-atelier.xml",
            waitingRoomAssets: "/assets/img/waitingroom/",
            lecturehallBg: "/assets/img/gsk-bg.png",
            losTrackingEndpoint: "https://lostracking.services.infillqa.com/",
            clickTracking: {
                endpoint: "https://infill-service-clicktracking-gcr-qq72lvua4q-ey.a.run.app",
                token: "jDt6sG2rqgsEjYFp5DBpe8eftKmyKauGSKcBN5ZjQQgH2LLnH5zK6uKJT3LgTgcT",
                tenantId: "vd2021q3"
            }
        }
    ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
