import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { IOrganizationSettings, OAuthStorageAdapter } from "../../OAuthStorageAdapter";
import { BaseComponent } from "../BaseComponent";
import { LosTrackingService } from "src/app/services/lostracking.service";
import * as moment from "moment";

declare var js3q;

@Component({
    selector: "app-lecturehall",
    templateUrl: "./lecturehall.component.html",
    styleUrls: ["./lecturehall.component.scss"]
})
export class LectureHallComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    public js3qVideoPlayer: any = null;
    public chatVisible = false;
    public pigeonVisible = false; // window.innerWidth >= 1200 === true;
    public helpVisible = false;

    public organizationSettings: IOrganizationSettings;

    constructor(private oauthStorageAdapter: OAuthStorageAdapter, private router: Router, private losTrackingService: LosTrackingService) {
        super();

        const auth = this.oauthStorageAdapter.getCurrentOAuthResult().orDefault(null);
        this.organizationSettings = this.oauthStorageAdapter.getOrganizationSettings();

        const webinarStart = moment(this.organizationSettings.webinarStart, environment.webinarTimeformat);
        const now = moment();

        // GMP-22: go to waiting room if webinar did not start yet
        if (this.organizationSettings.waitingRoomFeature && now.isBefore(webinarStart.subtract(30, "seconds"))) {
            this.router.navigate(["/waitingroom"]);
            return;
        }
    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        const _js3qi = setInterval(() => {
            if (typeof js3q !== "undefined") {
                clearInterval(_js3qi);
                this.js3qVideoPlayer = new js3q({
                    dataid: this.organizationSettings.id3qsdn,
                    container: "webinarPlayer"
                });
            }
        }, 10);

        const auth = this.oauthStorageAdapter.getCurrentOAuthResult().orDefault(null);
        const jwt = auth.jwt ?? "";

        this.subscriptions.push(
            this.losTrackingService.cloudflareLookup().subscribe(
                (result) => {
                    const data: any = result
                        .trim()
                        .split("\n")
                        .reduce((obj, pair: any) => {
                            pair = pair.split("=");
                            return (obj[pair[0]] = pair[1]), obj;
                        }, {});

                    this.losTrackingService.setupSocketConnection(jwt, "lecturehall", data?.loc);
                },
                (err) => {
                    this.losTrackingService.setupSocketConnection(jwt, "lecturehall", undefined);
                }
            )
        );
    }

    ngOnDestroy(): void {
        this.losTrackingService.closeSocketConnection();
    }

    toReception() {
        this.router.navigate(["/reception"]);
    }

    togglePigeon() {
        this.pigeonVisible = !this.pigeonVisible;
    }

    toggleHelp() {
        this.helpVisible = !this.helpVisible;
    }

    openFotoAction() {
        window.open("https://goto.fotoshare.online/gsk", "_blank");
    }
}
