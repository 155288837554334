import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, CanActivate, RouterStateSnapshot } from "@angular/router";
import { OAuthStorageAdapter } from "./OAuthStorageAdapter";
import { AuthenticationService } from "./services/authentication.service";

const REDIRECTTO = "/noie";

@Injectable()
export class IEGuard implements CanActivate {
    constructor(private router: Router, private oauthStorageAdapter: OAuthStorageAdapter, private oauthService: AuthenticationService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
        if (isIEOrEdge) {
            this.router.navigate([REDIRECTTO]);
            return false;
        }

        return true;
    }
}
