<div class="app-login">
    <div class="company-logo">
        <img src="assets/logo.png" />
    </div>

    <div *ngIf="!disableLogin">
        <form name="form" #f="ngForm">
            <div class="cross-validation">

                <div class="form-group form-group--float form-group--centered">
                    <input #email="ngModel" [(ngModel)]="loginEmail" name="email" type="text" class="form-control" email required />
                    <label>{{ "login.labels.email" | translate }}</label>
                    <div class="validation-error alert alert-danger" *ngIf="email.invalid && (email.dirty || email.touched)">
                        <p>{{ 'login.validation.email' | translate }}</p>
                    </div>
                </div>

                <div class="form-group form-group--float form-group--centered">
                    <input #password="ngModel" [(ngModel)]="loginPassword" name="password" type="password" class="form-control" required />
                    <label>{{ "login.labels.password" | translate }}</label>
                    <div class="validation-error alert alert-danger" *ngIf="password.errors?.required && (password.dirty || password.touched)">
                        <p>{{ 'login.validation.password' | translate }}</p>
                    </div>
                </div>

                <button (click)="login()" type="submit" class="btn btn-primary btn-block" [disabled]="f.invalid">
                    {{ "login.buttons.login" | translate }}
                </button>
            </div>
        </form>

        <!--
        <p class="text-center">
            <a [routerLink]="'/registration/'">{{ "login.links.register_account" | translate }}</a>
        </p>
        -->

        <p class="text-center">
            <a [routerLink]="'/reset_password/'">{{ "login.links.forgot_password" | translate }}</a>
        </p>
        <p>
            <b>Servicekontakt:</b><br/>
            Bei technischen Schwierigkeiten kannst Du Dich jederzeit an unseren Support wenden:<br/>
            Tel.: 02244 – 87 84 811<br/>
            E-Mail: <a href="mailto:support@infill.com">support@infill.com</a>
        </p>
    </div>
    
    <div *ngIf="disableLogin">
        <p>
            <b>Vielen Dank für Ihre Registrierung. Sie können sich hier ab dem 29.09. um 08:30 Uhr einloggen.</b>
        </p>
    </div>
</div>
