import { Injectable } from "@angular/core";
import { IRegistration } from "../models/IRegistration";
import { IRegistrationConfirmation } from "../models/IRegistrationConfirmation";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class RegistrationService {
    constructor(private http: HttpClient) {}

    register(registration: IRegistration): Observable<any> {
        const httpHeaders = new HttpHeaders({
            "Content-Type": "application/json"
        });
        return this.http.post<any>(environment.registrationsAPI, registration, { headers: httpHeaders });
    }

    confirmRegistration(registrationConfirmation: IRegistrationConfirmation): Observable<any> {
        const httpHeaders = new HttpHeaders({
            "Content-Type": "application/json"
        });
        return this.http.put<any>(environment.registrationsAPI, registrationConfirmation, { headers: httpHeaders });
    }

    initiatePasswordReset(email: string, organizationKey: string): Observable<any> {
        const httpHeaders = new HttpHeaders({
            "Content-Type": "application/json"
        });
        const body = {
            email: email,
            language: "en",
            organizationKey: organizationKey
        };

        return this.http.post(environment.resetPasswordAPI, body, { headers: httpHeaders });
    }

    performPasswordReset(token: string, password: string, passwordConfirm: string): Observable<any> {
        const httpHeaders = new HttpHeaders({
            "Content-Type": "application/json"
        });
        const body = {
            token: token,
            password: password,
            passwordConfirm: passwordConfirm
        };
        return this.http.put<any>(environment.resetPasswordAPI, body, { headers: httpHeaders });
    }

    // TODO: Implement account termination at customer request eventually.
}
