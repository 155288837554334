
<div id="lecturehall" [style]="'background-image: url(' + organizationSettings.lecturehallBg + ')'">
    <!-- [style.top]="this.webinarPlayerTop" [style.left]="this.webinarPlayerLeft" -->
    <div id="webinarPlayerContainer" [class.pigeonhole-visible]="pigeonVisible">
        <div id="webinarPlayer"></div>
    </div>

    <div id="chatContainer" [class.chat-visible]="chatVisible">
        
    </div>
    <div class="pigeonhole-iframe" [class.pigeonhole-visible]="pigeonVisible">
        <iframe src="https://vevox.app/#/m/173008760"></iframe>
    </div>

    <button class="gsk-btn bottom-left-nav-btn" mat-raised-button (click)="toReception()">
        <mat-icon class="back-btn">navigate_before</mat-icon>
        {{ 'lecturehall.buttons.reception' | translate }}
    </button>

    <div class="bottom-right-nav-btn">

        <button class="gsk-btn" mat-raised-button (click)="toggleHelp()">
            {{ "lecturehall.buttons.helpToggle" | translate }}
        </button>

        <button class="gsk-btn" style="margin-left: 25px;" mat-raised-button (click)="openFotoAction()">
            {{ 'lecturehall.buttons.fotoAction' | translate }}
        </button>

        <button class="gsk-btn" style="margin-left: 125px;" mat-raised-button (click)="togglePigeon()">
            {{ 'lecturehall.buttons.toggleQA' | translate }}
        </button>
    </div>
</div>


<div id="helpBackground" *ngIf="helpVisible" (click)="toggleHelp()"></div>

<div id="helpFrame" *ngIf="helpVisible">
    <div>
        <br/>
        <b>Servicekontakt:</b><br/>
        Tel.: 02244 – 87 84 811 <br/>
        E-Mail: <a href="mailto:support@infill.com">support@infill.com</a><br/><br/>

        <b>Organisationsteam MCI:</b><br/>
        Christina Behring <br/>
        Tel.: 089 – 54 90 96 18 <br/>
        E-Mail: <a href="mailto:christina.behring@mci-group.com">christina.behring@mci-group.com</a> <br/>
        E-Mail: <a href="mailto:gsk@mci-group.com">gsk@mci-group.com</a><br/>
    </div>
</div>

<button *ngIf="helpVisible" id="helpFrameCloseButton" (click)="toggleHelp()" title="Close">
    <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24"><path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path></svg>
</button>
