<div class="app-trigger-password-reset" *ngIf="showTriggerPasswordResetView">
    <form name="form" #f="ngForm">
        <div class="cross-validation" [class.cross-validation-error]="f.invalid && (f.touched || f.dirty)">
            <div class="form-group form-group--float form-group--centered">
                <input #email="ngModel" [(ngModel)]="resetPasswordEmail" name="email" type="text" class="form-control" email required />
                <label>{{ "password_reset.labels.email" | translate }}</label>
                <div class="validation-error alert alert-danger" *ngIf="email.invalid && (email.dirty || email.touched)">
                    <p>{{ "login.validation.email" | translate }}</p>
                </div>
            </div>

            <button (click)="onSendLink()" class="btn btn-primary btn-block" type="submit" [disabled]="f.invalid">
                {{ "password_reset.buttons.send_link" | translate }}
            </button>
        </div>
    </form>
</div>

<div class="app-reset-triggered" *ngIf="showPasswordResetTriggeredView">
    <h4>{{ "password_reset.titles.link_sent" | translate }}</h4>
    <p>{{ "password_reset.paragraphs.link_sent_confirmation" | translate }}</p>
</div>

<div class="app-perform-password-reset" *ngIf="showPerformPasswordResetView">
    <form name="form" #f="ngForm">
        <div class="cross-validation" [class.cross-validation-error]="f.invalid && (f.touched || f.dirty)">

            <div class="form-group">
                <input #password="ngModel" [(ngModel)]="resetPassword" name="resetPassword" type="password" class="form-control" required />
                <label>{{ "password_reset.labels.password" | translate }}</label>
                <div class="validation-error alert alert-danger" *ngIf="password.errors?.required && (password.dirty || password.touched)">
                    <p>{{ "password_reset.validation.password" | translate }}</p>
                </div>
            </div>

            <div class="form-group">
                <input #passwordConfirm="ngModel" [(ngModel)]="resetPasswordConfirm" name="resetPasswordConfirm" type="password" class="form-control" required />
                <label>{{ "password_reset.labels.password_confirm" | translate }}</label>
                <div class="validation-error alert alert-danger" *ngIf="!(this.resetPasswordConfirm === this.resetPassword)">
                    <p>{{ "password_reset.validation.password_confirm_mismatch" | translate }}</p>
                </div>
            </div>

            <button (click)="onPerformPasswordReset()" class="btn btn-primary btn-block">
                {{ "password_reset.buttons.change_password" | translate }}
            </button>
        </div>
    </form>
</div>
